<template>
  <!-- <div > -->
  <div class="framePage-scroll editLiveClass">
    <div class="ovy-a">
      <el-form
        class="el-form-liveClass"
        ref="Form"
        :model="Form"
        disabled
        :rules="rules"
        label-width="130px"
      >
        <el-form-item
          label="班级名称："
          prop="liveProjectName"
          class="form-item"
        >
          <el-input
            v-model="Form.liveProjectName"
            placeholder="请输入班级名称"
            maxlength="50"
            show-word-limit
            size="small"
          />
        </el-form-item>
        <el-form-item label="行政区划：" prop="areaId" class="form-item">
          <el-cascader
            v-model="Form.areaId"
            clearable
            filterable
            :options="areatreeList"
            :props="propsarea"
            size="small"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="有效日期：" prop="Effectivedate" class="form-item">
          <el-date-picker
            size="small"
            v-model="Form.Effectivedate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="所属期次："
          prop="periodId"
          class="form-item form-period"
        >
          <el-input
            v-model="Form.periodName"
            placeholder="请输入所属期次"
            size="small"
          />
        </el-form-item>
        <el-form-item label="负责人：" prop="projectUser" class="form-item">
          <el-input
            v-model="Form.projectUser"
            placeholder="请输入负责人"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="负责人电话："
          prop="projectUserPhone"
          class="form-item"
        >
          <el-input
            v-model="Form.projectUserPhone"
            placeholder="请输入负责人电话"
            maxlength="12"
            size="small"
          />
        </el-form-item>
        <el-form-item label="培训类型："  class="form-item">
           <el-input
            v-model="Form.trainTypeNamePath"
            type="text"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="培训人数"
          prop="projectPeople"
          class="form-item"
          required
        >
          <el-input
            v-model="Form.projectPeople"
            style="width: 100%"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-btn">
        <el-button @click="cancle" class="bgc-bv">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "liveDetail",
  components: {
    tree,
  },
  mixins: [UploadPic, DatePicker],
  data() {
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的电话号码"));
      } else {
        callback();
      }
    };

    return {
      disabled: false,
      paperId: false,
      editJson: {},
      areatreeList: [],
      datatreeList: [], //培训类型
      props: {
        value: "id",
        label: "label",
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      selectType: "0",
      Form: {},
      ruleForm: {
        Trainingtype: "",
      },
      params: {},
      rules: {
        liveprojectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        projectCrowd: [
          { required: true, message: "请选择人群类型", trigger: "change" },
        ],
        Trainingtype: [
          {
            required: true,
            message: "请选择培训类型",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  created() {
    this.editJson = this.$route.query;
    this.liveProjectId = sessionStorage.getItem("liveProjectId");
    this.projectState = sessionStorage.getItem("projectState");
    this.getareatree();
    this.loadData(this.liveProjectId);
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    loadData(liveProjectId) {
      this.$post(
        "/liveProject/live-project/getLiveProject",
        { liveProjectId },
        3000,
        true,
        3
      )
        .then((ret) => {
          this.Form = ret.data;
          this.Form.Effectivedate = [
              ret.data.startDate.replaceAll("/", "-"),
              ret.data.endDate.replaceAll("/", "-")
          ]
        })
        .catch((err) => {
          return;
        });
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    cancle() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scope>
.editLiveClass {
  .el-form-liveClass {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 45%;
    }
    .form-period {
      .el-form-item__content {
        display: flex;
        align-items: center;
        .el-select {
          flex: 1;
        }
      }
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
