<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">直播管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.push('/web/liveBroadcastManagement/liveTheClassList')">直播列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{liveProjectName}}-详情</a>
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
          <el-tab-pane label="基本信息" name="first">
            <liveDetail ref="first" />
          </el-tab-pane>
          <el-tab-pane label="直播课程" name="second">
            <liveClassCourse ref="second" :flag="true" />
          </el-tab-pane>
          <el-tab-pane label="学员列表" name="three">
            <liveStudentInfo ref="three" />
          </el-tab-pane>
          <el-tab-pane label="参数设置" name="four">
            <liveClassParameter v-if="activeName == 'four'" key="four" ref="four" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import liveDetail from "@/views/liveBroadcastManagement/liveTheClassList/liveDetail";
import liveClassCourse from "@/views/liveBroadcastManagement/liveClassCourse";
import liveStudentInfo from "@/views/liveBroadcastManagement/liveTheClassList/liveStudentInfo";
import liveClassParameter from "@/views/liveBroadcastManagement/liveTheClassList/liveClassParameter";
import { mapGetters } from "vuex";
export default {
  name: "ClassdetailsList",
  components: {
    liveDetail,
    liveClassCourse,
    liveStudentInfo,
    liveClassParameter
  },
  mixins: [List],
  data() {
    return {
      activeName: "",
      liveProjectName: "",
    };
  },
  mounted() {},
  watch: {
    activeName: function(nval, oval) {
      if (nval && nval != "first"&&nval != "four") {
        this.$refs[nval].getData();
      }
    }
  },
  computed: {
    ...mapGetters({
      userJson: "getUser"
    })
  },
  methods: {
    init() {
      this.liveProjectName = sessionStorage.getItem("liveProjectName");
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      }
    },
    tabChange(tab) {
      this.activeName = tab.name;
      this.$refs['three'].userName = "";
      this.$refs['three'].idcard = "";
      this.$refs['three'].mobile = "";
      this.$refs['three'].completeState = "";
      // if (tab.name == "three") {
      //   this.$refs.three.openOnce();
      // }
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    },
  }
};
</script>
<style lang="less" scoped>
.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
}
#pane-first {
  display: flex;
  flex: 1;
  overflow: hidden;
}
</style>
<style lang="less">
.framePage-tabs {
  .el-tabs {
    width: 100%;
  }
  .el-tabs__content {
    overflow-y: auto;
  }
}
</style>
